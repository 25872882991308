import http from "../../utils/http"

/**
 * 获取公告列表
 */
export function classList(params) {
    return http({
        url: "/api/news/classlists",
        data: params
    })
}
/**
 * 获取公告列表
 */
export function newsList(params) {
    return http({
        url: "/api/news/page",
        data: params
    })
}
/**
 * 获取公告详情
 */
export function newsDetail(params) {
	console.log(".ffffffff.",JSON.stringify(params));
    return http({
        url: "/api/news/info",
        data: params
    })
}
